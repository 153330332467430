import ObjectHelper from '@/utils/objectHelper'
import cloneDeep from 'clone-deep'
import { format, parseISO } from 'date-fns'

const DataTableHelper = {
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            tableDataArr: [],
            itemKey: null,
            fieldNames: []
        }
    }
}

const EditableDataTableHelper = {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            activeRowId: null,
            activeRowObj: null,
            currentNewId: null,
        }
    },
    mounted() {
        this.refreshTableData()
    },
    computed: {
        selecetdRows: {
            get() {
                return this.activeRowObj ? [this.activeRowObj] : []
            },
            set() { }
        }
    },
    methods: {
        getFormattedDate(date) {
            return format(parseISO(date), 'yyyy/MM/dd')

        },
        formatDDMMYYYY(date) {
             return format(parseISO(date), "dd/MM/yyyy")
        },
        refreshTableData() {
            this.tableDataArr = [...this.tableData]
            if (!this.tableDataArr.length) this.addNewRow()
        },
        addNewRow() {
            if (this.isNewRowExisting()) return

            if (this.isActiveRowExisting() && this.isActiveRowChanged()) {
                this.showDiscardMessage(true)
                return false
            }

            console.debug('Adding a new row into editable data table')

            const newItemId = Math.floor(Math.random() * 1000000) + 5000000
            const newObject = { ...this.getDefaultNewObject() }
            newObject[this.itemKey] = newItemId

            this.tableDataArr.push(newObject)

            this.currentNewId = newItemId
            this.setActiveRow(newItemId)

            this.page = parseInt((this.tableDataArr.length - 1) / this.itemsPerPage) + 1
        },
        isNewRowExisting() {
            return this.currentNewId
        },
        isActiveRowExisting() {
            return this.activeRowId
        },
        isActiveRowChanged() {
            const changedObj = this.getActiveRow(this.tableDataArr)
            if (!changedObj) return false

            return this.fieldNames.filter(fieldName => this.isFieldChanged(this.activeRowObj, changedObj, fieldName)).length > 0
        },
        isFieldChanged(obj, changedObj, fieldName) {
            const val = obj[fieldName], changedVal = changedObj[fieldName]
            if (val != null && changedVal != null
                && ObjectHelper.isObject(val) && ObjectHelper.isObject(changedVal)) {
                return !ObjectHelper.deepEqual(val, changedVal)
            }

            return val != changedVal
        },
        setActiveRow(id) {
            this.activeRowId = id
            this.activeRowObj = this.getActiveRow()
        },
        toggleEdit(val) {
            if (!this.isEditable) return false
            if (this.currentNewId) {
                this.showDiscardMessage(false)
                return false
            } else if (this.activeRowId && this.isActiveRowChanged()) {
                this.showDiscardMessage(true)
                return false
            }

            this.setActiveRow(val)
        },
        showDiscardMessage(isEdit) {
            this.type = 'warning'
            this.message = `Please save/discard the ${isEdit ? 'editing' : 'new'} row before moving to other rows.`
            this.showMessage({ duration: 1500 })
        },
        getActiveRow() {
            const activeRow = this.tableDataArr.find((item) => {
                return item[this.itemKey] === this.activeRowId
            })
            return cloneDeep(activeRow)
        },
        cancelHandler() {
            this.resetTableRow()
            this.clearRowSelection()
        },
        resetTableRow() {
            // if an existing row is being edited
            if (!this.currentNewId) {
                // update the data on the row to the original values stored in activeRowObj
                this.tableDataArr = this.tableDataArr.map(item => {
                    if (item[this.itemKey] === this.activeRowId) {
                        return this.activeRowObj
                    }
                    return item
                })
            } else {
                // if its a new staff member, cancel the add by filtering them from the table arr
                this.tableDataArr = this.tableDataArr.filter(item => {
                    if (item[this.itemKey] !== this.activeRowId) return item
                })
            }
        },
        clearRowSelection() {
            this.currentNewId = null

            this.activeRowId = null
            this.activeRowObj = null
        }
    },
    watch: {
        tableData() {
            this.clearRowSelection()
            this.refreshTableData()
        },
        activeRowId() {
            this.tableDataArr = this.tableDataArr.map(item => {
                let isActive = item[this.itemKey] === this.activeRowId
                return {
                    ...item,
                    active: isActive
                }
            })
        }
    }
}

export {
    DataTableHelper,
    EditableDataTableHelper
}
