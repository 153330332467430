const SharedMixin = {
    methods: {
        getDisplayValue(val, displayList, prop = 'label') {
            if (!displayList || displayList.length === 0 || !val) return ''

            const displayValue = displayList.find(t => t.value?.toLowerCase() === val?.toLowerCase())
            return displayValue != null ? displayValue[prop] : ''
        },
    }
}

export {
    SharedMixin
}
