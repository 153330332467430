<template>
    <div>
        <component
            :is="renderForm"
            :model="isValid"
            ref="formInput"
            class="c-editable-cell"
            @submit.prevent=""
        >
            <component
                dense single-line hide-details
                :is="renderMenu"
                :ref="`itemMenu-${cellId}`"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="formInput.inputValue"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                v-show="editActive"
                :label="label"
            >
                <template v-slot:activator="{ on, attrs }">
                    <component dense single-line hide-details
                        :is="renderInput"
                        v-model="computedInputValue"
                        :label="label"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        v-show="editActive"
                        :rules="isRequired ? [required] : []"
                        @focus="periodChanged(defaultPeriod)"
                    ></component>
                </template>
                <component
                    hide-details
                    ampm-in-title
                    format="ampm"
                    :is="editableComponent"
                    v-if="menu"
                    v-model="formInput.inputValue"
                    full-width
                    @click:minute="$refs[`itemMenu-${cellId}`].save(formInput.inputValue)"
                    @update:period="periodChanged"
                ></component>
            </component>
        </component>
        <a
            @click="cellClickHandler"
            v-show="!editActive"
            class="justify-start py-1 m-0 hover:text-grey-1 text-grey-2 leading-normal border border-transparent border-l-0 border-r-0 border-t-0"
            :v-html="!linkText ? '&nbsp;': ''"
            :class="{'w-4/5' : !linkText, 'fieldEmpty': !formInput.inputValue, 'requiredField': this.isRequired}"
        >
            {{ linkText }}
        </a>
    </div>
</template>

<script>
import { FormRules } from '@/components/shared/mixins/formMixins'
import { format, parse, isValid } from 'date-fns'

export default {
    name: 'TimePickerCell',
    props: {
        value: {
            type: String,
            default: ''
        },
        displayValue: {
            type: String,
            default: ''
        },
        cellId: {
            type: [Number, String],
            default: null
        },
        editableComponent: {
            type: String,
            default: 'v-time-picker'
        },
        editActive: {
            type: Boolean,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        defaultValue: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'Enter value'
        }
    },
    mixins: [ FormRules ],
    data() {
        return {
            period: '',
            isValid: true,
            menu: false,
            focused: false,
            formInput: {inputValue: ''},
            defaultPeriod: 'am'
        }
    },
    mounted() {
        this.period = this.defaultPeriod
        const value = this.value || this.defaultValue
        if (value.indexOf(' ') > -1) {
            const date = parse(value, 'hh:mm a', new Date())
            this.formInput.inputValue = format(date, 'HH:mm')
        } else {
            this.formInput.inputValue = value
        }
    },
    methods: {
        cellClickHandler() {
            if (this.disabled) return false
            this.focused = true
            this.$emit('edit-row', this.cellId)
        },
        onCellClick(e) {
            console.log('onCellClick', e)
        },
        validate() {
            this.isValid = this.$refs.formInput.validate()
            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            return this.isValid
        },
        periodChanged(val) {
            this.period = val
            // const format = this.formInput.inputValue
            console.log('period changed', val)
        }
    },
    computed: {
        linkText(){
            let text =  this.displayValue || this.value
            return text || ''
        },
        renderEditableComponent() {
            if (this.editActive) return this.editableComponent
            return ''
        },
        renderForm() {
            if (this.editActive) return 'v-form'
            return ''
        },
        renderMenu() {
            if (this.editActive) return 'v-menu'
            return ''
        },
        renderInput() {
            if (this.editActive) return 'v-text-field'
            return ''
        },
        computedInputValue() {
            const date = parse(this.formInput.inputValue, 'HH:mm', new Date())
            return isValid(date) ? `${format(date, 'h:mm a')}` : ''
        }
    },
    watch: {
        value() {
            this.period = this.value && this.value.indexOf(' ') > -1 ? this.value.split(' ')[1] : ''
            const date = parse(this.value, 'hh:mm a', new Date())
            this.formInput.inputValue = format(date, 'HH:mm')
            // this.formInput.inputValue = this.value
        },
        'formInput.inputValue': function() {
            const date = parse(this.formInput.inputValue, 'HH:mm', new Date())
            if (!isValid(date)) return

            const emitDate = `${format(date, 'hh:mm a')}`
            if (this.editActive) this.$emit('input', emitDate)
        },
    },
}
</script>
