<template>
    <div>
        <component
            :is="renderForm"
            :model="isValid"
            ref="formInput"
            class="c-editable-cell"
            @submit.prevent=""
        >
            <component
                :is="'v-menu'"
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :attach = "attachClass"
                transition="scale-transition"
                offset-y
                max-width="290"
                @input="onMenuToggle"
            >
                <template v-slot:activator="{ on, attrs }">
                    <component
                        :is="'v-text-field'"
                        dense single-line hide-details
                        ref="date"
                        v-model="computedDate"
                        label="dd/mm/yyyy"
                        v-bind="attrs"
                        v-on="on"
                        :rules="rules"
                        :hint="hint"
                        :persistent-hint="showHint"
                        :placeholder="placeholder"
                        :readonly="readOnly"
                        @blur="dateChanged"
                    ></component>
                </template>
                <component
                    :is="renderEditableComponent"
                    v-if="!readOnly"
                    color="bg-white-pure"
                    ref="datePicker"
                    v-model="date"
                    v-bind="$attrs"
                    @change="save"
                ></component>
            </component>
        </component>
        <a
            v-show="!editActive"
            class="justify-start py-1 m-0 hover:text-grey-1 text-grey-2 leading-normal border border-transparent border-l-0 border-r-0 border-t-0"
            :class="{'w-4/5' : !date, 'fieldEmpty': !date, 'requiredField': this.isRequired}"
            @click="cellClickHandler"
        >
            {{ computedDate }}
        </a>
    </div>
</template>

<script>
import { format, isValid, parse, parseISO } from 'date-fns'

export default {
    name: 'DatePickerCell',
    props: {
        value: {
            type: [ Date, String ],
            default: () => {}
        },
        defaultDate: {
            type: Date,
            default: () => {}
        },
        cellId: {
            type: [Number, String],
            default: null
        },
        editableComponent: {
            type: String,
            default: 'v-date-picker'
        },
        editActive: {
            type: Boolean,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        hint: {
            type: String,
            default: '',
        },
        showHint: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: ''
        },
        rules: {
            type: Array,
            default: () => []
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        attachClass: {
            type: [String, Boolean],
            default: false
        }
    },
    data() {
        return {
            isValid: true,
            focused: false,
            date: null,
            prevDate: null,
            dateMenu: false,
            manuallyEnteredDate: null
        }
    },
    mounted() {
        // if (this.isRequired) console.log('this is required', this.value)
        if (this.value != null) this.date = this.value
        if (this.defaultDate && (this.value == null || this.date == undefined || !Object.keys(this.value).length) ) this.date = format(this.defaultDate, 'yyyy-MM-dd')
    },
    methods: {
        cellClickHandler() {
            this.focused = true
            this.$emit('edit-row', this.cellId)
        },
        onMenuToggle(opened) {
            if (opened) this.prevDate = this.date
            else if (!this.date) this.date = this.prevDate
        },
        clearValidate(){
            this.isValid = true
        },
        validate() {
            this.isValid = this.$refs.date.validate()
            return this.isValid
        },
        save(date) {
            this.$refs.dateMenu.save(date)
            this.dateMenu = false
        },
        dateChanged(e) {
            if (this.readOnly) return

            if (!this.manuallyEnteredDate) {
                this.date = this.manuallyEnteredDate
                return
            }

            const date = parse(this.manuallyEnteredDate, 'dd/MM/yyyy', new Date())
            if (isValid(date)) {
                this.date = format(date, 'yyyy-MM-dd')
                this.manuallyEnteredDate = null
            }
        }
    },
    computed: {
        renderEditableComponent() {
            if (this.editActive) return this.editableComponent
            return ''
        },
        renderForm() {
            if (this.editActive) return 'v-form'
            return ''
        },
        computedDate: {
            get() {
                if (!this.date) return ''
                return format(parseISO(this.date), 'dd/MM/yyyy')
            },
            set(val) {
                this.manuallyEnteredDate = val
            }
        }
    },
    watch: {
        value() {
            console.log('date passed in', this.value)
            if (this.value != null) this.date = this.value
        },
        date() {
            this.$emit('input', this.date)
        }
    },
}
</script>
