<template>
    <div class="flex justify-space-around">
        <div class="w-160">
            <DateRangePicker
                :maxDate="null"
                :row="false"
                :wrap="false"
                :outlined="true"
                :dense="true"
                :solo="false"
                :single-line="true"
                :value="dateRangeValue"
                @input="filterHandlerValue"
            />
        </div>
    </div>
</template>
<script>
import DateRangePicker from '@/components/partials/DateRangePicker.vue'
import { mapState } from 'vuex'
import { useGroupFilterStore } from '@/stores/useGroupFilterStore'
import { mapStores } from 'pinia'

export default {
    name: 'GroupFilter',
    components: { DateRangePicker },
    data() {
        return {
            dataFilter: {
                startDate: null,
                endDate: null,
                locationProgramId: null,
            },
            dateRangeValue: [],
        }
    },
    emits: ['setGroupFilter'],
    methods: {
        filterHandlerValue(value) {
            this.dateRangeValue = value
            if (value.length === 2) {
                (this.dataFilter.startDate = value?.[0] ? value?.[0] : null),
                    (this.dataFilter.endDate = value?.[1] ? value?.[1] : null),
                    this.$emit('setGroupFilter', this.dataFilter)
            }
        },

    },
    computed: {
        ...mapStores(useGroupFilterStore),
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
        })
    },
    watch: {
        'groupFilterStore.resetDateFilter'() {
            if (this.groupFilterStore.resetDateFilter) {
                this.dateRangeValue = []
                this.dataFilter.startDate = null
                this.dataFilter.endDate = null
                this.groupFilterStore.setResetDateFilter(false)
            }
        }
    }
}
</script>