import { reactive, toRefs } from '@vue/composition-api'
import { defineStore } from 'pinia'

export const useGroupFilterStore = defineStore('groupFilter', () => {
    const state = reactive({
        resetDateFilter: false
    })

    const setResetDateFilter = (value) => {
        state.resetDateFilter = value
    }

    return {
        ...toRefs(state),
        setResetDateFilter
    }
})